.comune{
    h1,h2,h4{

        //font-size: 5rem;
        color: white;
         text-shadow: 2px 0px black;
    }
    h1{
        font-family: 'Luxurious Script', cursive;
        font-size:5rem;
    }
    h2{
        //font-size:3rem
    }

    .content{
        img.stemma{
            max-height: 300px;;
        }
    }
    background-color: #7F95D1;

    .comuni-list{
        a{

            text-decoration: none;
            background-color: #7f95d1;
            color: white;
            &:hover{
                background-color: white;
                color:#7f95d1;
            }
        }
        .stemma-small{
            max-height: 60px;
        }
    }


}

.altri-comuni{
    .list {
        a {

            border: 1px solid;
            border-radius: 2px;
            margin: 1px;
            text-decoration: none;
            color: #7f95d1;
            &:hover{
                color:white;
                background-color: #7f95d1;
            }
        }

    }
}

body{
    background-color: #FFEBE7;
    .navbar{
        background-color: #558B6E;
    }

    footer{
        background-color: #558B6E;
    }
}


table.popolazione{

    th,
    td{

        text-align: center;
    }
    td{
        &:first-child{
            border-right: 1px solid #ccc;
            font-weight: bold;
        }
        &:last-child{
            border-left:2px solid #ccc;
        }
    }
}

table.distanze{
    tbody.distanti{
        background-color: beige;
    }
}
